
.editor-paragraph {
  margin: 0;
  position: relative;
}

.editor-paragraph + .editor-paragraph {
  margin-top: 10px;
  position: relative;
}

.editor-quote {
  border-left-width: 5px;
  border-left-color: #000;
  border-radius: 5px;
  margin-left: 0px;
  border-left-style: solid;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.editor-heading-3 {
  margin-top: 0px;
}

* + .editor-heading-3 {
  margin-top: 20px;
  position: relative;
}